<template>
  <div class="base-list-page">
    <el-form
      :inline="true"
      :model="searchForm"
      class="bysearchForm"
      @keyup.enter.native="getDataList()"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-select
            v-model="searchForm.state"
            placeholder="状态"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.name"
              :value="item.state"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
          <el-button
            v-if="isAuth('cc:freezerApplication:save')"
            type="primary"
            @click="addOrUpdateHandle(null, false)"
            >申请</el-button
          >
          <el-button
            v-if="isAuth('cc:freezerApplication:apply')"
            :disabled="
              !(
                dataListSelections.length === 1 &&
                dataListSelections[0].state === 0
              )
            "
            type="primary"
            @click="addOrUpdateHandle(dataListSelections[0].id, true, true)"
            >审核</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="orgName"
        header-align="center"
        align="center"
        width="150"
        label="配餐公司"
      ></el-table-column>
      <el-table-column
        prop="address"
        header-align="center"
        align="center"
        width="120"
        label="投放位置"
      ></el-table-column>

      <el-table-column
        prop="periodNames"
        header-align="center"
        align="center"
        width="150"
        label="供应时段"
      ></el-table-column>

      <el-table-column
        prop="isGroup"
        header-align="center"
        align="center"
        width="120"
        label="是否团餐"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isGroup" size="small">是</el-tag>
          <el-tag v-else size="small" type="danger">否</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        prop="diningNum"
        header-align="center"
        align="center"
        width="150"
        label="供餐人数"
      >
      </el-table-column>

      <el-table-column
        prop="state"
        header-align="center"
        align="center"
        width="120"
        label="状态"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.state === 0" size="small">审核中</el-tag>
          <el-tag v-else-if="scope.row.state === 1" size="small" type="success"
            >已通过</el-tag
          >
          <el-tag v-else-if="scope.row.state === 2" size="small" type="danger"
            >未通过</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="createdBy"
        header-align="center"
        align="center"
        width="120"
        label="申请人"
      ></el-table-column>
      <el-table-column
        prop="createdAt"
        header-align="center"
        align="center"
        width="120"
        label="申请时间"
      ></el-table-column>
      <el-table-column
        prop="updatedBy"
        header-align="center"
        align="center"
        width="180"
        label="审核人"
      ></el-table-column>
      <el-table-column
        prop="updatedAt"
        header-align="center"
        align="center"
        width="120"
        label="审核时间"
      ></el-table-column>
      <el-table-column
        width="180"
        header-align="center"
        align="center"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('cc:freezerApplication:info')"
            type="text"
            size="small"
            @click="showDetails(scope.row.id, true)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './freezer-apply-add-or-update';
import { listMixin, normal, threeListMixin } from '@/mixins';

export default {
  mixins: [threeListMixin, listMixin, normal],
  data() {
    return {
      searchForm: {
        state: '',
      },
      stateList: [
        {
          state: 0,
          name: '审核中',
        },
        {
          isUse: 1,
          name: '已通过',
        },
        {
          isUse: 2,
          name: '未通过',
        },
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/cc/freezerApplication/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          ...this.searchForm,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 查看详情
    showDetails(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, true);
      });
    },
    // 新增 / 修改
    addOrUpdateHandle(id, disabled, isApply) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, disabled, isApply);
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否确认对这些只智能取餐柜进行删除操作?`,
        url: '/cc/freezerApplication/delete',
        data: ids,
        after: () => {
          this.getDataList();
        },
      });
    },
  },
};
</script>
